import React, {useState,useEffect} from 'react'
import Body from './components/BrowsingPage/BrowsingPage.jsx'

export default function BrowsingPage() {
  
  return (
    <>
    <Body/>
    </>
  )
}