export const UserData = {
  0: {
    userName: '',
    userAvatar: null,
    descrip: 'description of testing-user0'
  },
  1: {
    userName: 'Ernest Mccartney',
    userAvatar: null,
    descrip: 'description of testing-user1'
  },
  2: {
    userName: 'Lucas LLoyd',
    userAvatar: null,
    descrip: 'description of testing-user2'
  },
  3: {
    userName: 'Tom Douglas',
    userAvatar: null,
    descrip: 'description of testing-user3'
  },
  4: {
    userName: 'H. W. Kishor',
    userAvatar: null,
    descrip: 'description of testing-user4'
  },
  5: {
    userName: 'Ken Adams',
    userAvatar: null,
    descrip: 'description of testing-user5'
  },
  6: {
    userName: 'Arthur White',
    userAvatar: null,
    descrip: 'description of testing-user6'
  },
  7: {
    userName: 'Test',
    userAvatar: null,
    descrip: 'description of testing-user7'
  },
}
