export var writingNoteData = {
  "Characters": {
    "char1": "notes for char1",
    "char2": "notes for char2",
  },
  "Others": {
    "Settings": {
      "settings0": "",
    },
    "Factions": {
    }
  }
}