import cover1 from '../Assets/bookcover1.jpg';
import cover2 from '../Assets/bookcover2.jpg';
import cover3 from '../Assets/bookcover3.jpg';




export const ListMonthly = [
    {
    title: 'Hungry Bird',
    image: cover1,
    cName: 'nav-text',
    secondarytext: "one"
    },
    {
    title: 'The Arrivals',
    image: cover2,
    cName: 'nav-text',
    secondarytext: "two"
    },
    {
    title: 'Mystic Emperors',
    image: cover3,
    cName: 'nav-text',
    secondarytext: "three"
    },

  ];