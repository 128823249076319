/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bf4cd315-590d-46b6-a8c0-73dc1d0e5d2f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xaE7M2WkK",
    "aws_user_pools_web_client_id": "56uv7mr34kbngpq2r18n4jbveg",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "BIRTHDATE",
        "EMAIL",
        "NAME",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "c6e2b35ff923464da2160b590965f2ca",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://5b45hrzz6bft5khbn2nopzlpy4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ui3wnttmavbptbjzjqk2eszdxi"
};


export default awsmobile;
