import React from 'react';
import clsx from 'clsx';
import { Container } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  
}));

const Body = ({ theme }) => {
  const classes = useStyles();
  return (
    <>
      
    </>
  )
}

export default Body;