import React from 'react';
import Body from './components/WritingPage/WritingTransitionPageBody.jsx'

const WritingPageTransitionPage = ({}) => {
  return (
    <div>
      <Body/>
    </div>
  )
}

export default WritingPageTransitionPage




