import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: '50px 0',
    marginTop: 64,
  }

}));

export default useStyles;